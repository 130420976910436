import { MarkerF, InfoWindowF } from "@react-google-maps/api";
import { useState, useEffect } from "react";

import markerPurpleFilled from "../assets/markerPurpleFilled.png";
import markerPurple from "../assets/markerPurple.png";
import markerBlueFilled from "../assets/markerBlueFilled.png";
import markerBlue from "../assets/markerBlue.png";
import markerRedFilled from "../assets/markerRedFilled.png";
import markerRed from "../assets/markerRed.png";
import markerOrangeFilled from "../assets/markerOrangeFilled.png";
import markerOrange from "../assets/markerOrange.png";
import markerYellowFilled from "../assets/markerYellowFilled.png";
import markerYellow from "../assets/markerYellow.png";
import markerGreenFilled from "../assets/markerGreenFilled.png";
import markerGreen from "../assets/markerGreen.png";

const RiderMarker = ({ setActiveMarker, activeMarker, riderDoc }) => {
  const [currentLocation, setCurrentLocation] = useState(null);
  const [SENSOR01, setSENSOR01] = useState(null);
  const [SENSOR02, setSENSOR02] = useState(null);
  const [iconImage, setIconImage] = useState(null);
  const [hasActiveTask, setHasActiveTask] = useState(false);

  useEffect(() => {
    try {
      setCurrentLocation(riderDoc.currentLocation);
      setSENSOR01(riderDoc.sensorData.SENSOR01);
      setSENSOR02(riderDoc.sensorData.SENSOR02);
      setHasActiveTask(riderDoc.hasActiveTask);
    } catch (error) {
      console.log(error, riderDoc);
    }
  }, [riderDoc]);

  useEffect(() => {
    if (SENSOR01 != null) {
      const changeIconImage = () => {
        let SENSOR01_PM25 = SENSOR01.PM25;
        if (SENSOR01_PM25 >= 90.5) {
          if (hasActiveTask) {
            setIconImage(markerPurpleFilled);
          } else {
            setIconImage(markerPurple);
          }
        } else if (SENSOR01_PM25 >= 50.5) {
          if (hasActiveTask) {
            setIconImage(markerBlueFilled);
          } else {
            setIconImage(markerBlue);
          }
        } else if (SENSOR01_PM25 >= 37.5) {
          if (hasActiveTask) {
            setIconImage(markerRedFilled);
          } else {
            setIconImage(markerRed);
          }
        } else if (SENSOR01_PM25 >= 25.5) {
          if (hasActiveTask) {
            setIconImage(markerOrangeFilled);
          } else {
            setIconImage(markerOrange);
          }
        } else if (SENSOR01_PM25 >= 12.4) {
          if (hasActiveTask) {
            setIconImage(markerYellowFilled);
          } else {
            setIconImage(markerYellow);
          }
        } else {
          if (hasActiveTask) {
            setIconImage(markerGreenFilled);
          } else {
            setIconImage(markerGreen);
          }
        }
      };
      changeIconImage();
    }
  }, [SENSOR01, hasActiveTask]);

  return (
    <>
      {currentLocation != null && iconImage != null && (
        <MarkerF
          position={{
            lat: currentLocation.latitude,
            lng: currentLocation.longitude,
          }}
          icon={{
            url: iconImage,
            scaledSize: new window.google.maps.Size(50, 50),
          }}
          onClick={() => {
            if (riderDoc.id === activeMarker) {
              return;
            }
            setActiveMarker(riderDoc.id);
          }}
        >
          {activeMarker === riderDoc.id ? (
            <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
              <div className="d-flex flex-column pe-2 pb-2">
                <table className="table  table-hover">
                  <thead>
                    <tr>
                      <th rowSpan={2}>Sensors</th>
                      <th colSpan={2}>Pollutant concentration (µg/m3)</th>
                    </tr>
                    <tr>
                      <th>PM 10</th>
                      <th>PM 2.5</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>1</th>
                      <td>{SENSOR01.PM10}</td>
                      <td>{SENSOR01.PM25}</td>
                    </tr>
                    <tr>
                      <th>2</th>
                      <td>{SENSOR02.PM10}</td>
                      <td>{SENSOR02.PM25}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </InfoWindowF>
          ) : null}
        </MarkerF>
      )}
    </>
  );
};

export default RiderMarker;
